// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-menu-js": () => import("./../../../src/templates/menu.js" /* webpackChunkName: "component---src-templates-menu-js" */),
  "component---src-templates-secondary-page-js": () => import("./../../../src/templates/secondary-page.js" /* webpackChunkName: "component---src-templates-secondary-page-js" */),
  "component---src-templates-special-menu-js": () => import("./../../../src/templates/special-menu.js" /* webpackChunkName: "component---src-templates-special-menu-js" */),
  "component---src-templates-your-review-js": () => import("./../../../src/templates/your-review.js" /* webpackChunkName: "component---src-templates-your-review-js" */)
}

